import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './js/components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if(!window.location.href.includes("localhost")){
  Sentry.init({
    dsn: "https://18419155a55e47239da6a11ebfe6ecfc@o298322.ingest.sentry.io/1552224",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


