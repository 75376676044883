
import Amplify, { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

export function useCurrentAuthenticatedUser(){
  const [user, setUser] = useState();

  async function getUser(){
    let currentUser;
    try {
      currentUser = await Auth.currentAuthenticatedUser({ bypassCache: false  });
      setUser(currentUser);
    } catch (e) {
      console.log('useCurrentAuthenticatedUser', e);
    }
  }

  useEffect(()=>{
    getUser();
  }, [])

  return [user, setUser];
}

export function login(username, password){
  return Auth.signIn(username, password);
}

export function register(username, email, password){
  return Auth.signUp({
    username,
    password,
    attributes: {
        email, 
    },
    validationData: []  //optional
    })
}

export function confirm(username, code){
  return Auth.confirmSignUp(username, code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true    
  })
}

export function resend(username) {
  return Auth.resendSignUp(username)
}

export function forgotPasswordCode(username){
  return Auth.forgotPassword(username)
}

export function forgotPasswordSubmit(username, code, new_password) {
  return Auth.forgotPasswordSubmit(username, code, new_password)
}

export function logout(){
  return Auth.signOut();
}

export function useCurrentSession(){
  const [session, setSession] = useState();

  async function getSession(){
    let currentSession;
    try {
      currentSession = await Auth.currentSession();
    } catch (e) {
      console.log('getSession error', e)
    }
    console.log('session', currentSession);
    setSession(currentSession);
  }

  useEffect(()=>{
    getSession();
  }, [])

  return session;
}

export function updateAttributes(user, attributes){
  return Auth.updateUserAttributes(user, attributes);
}