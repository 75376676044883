/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "shortener",
            "endpoint": "https://oauo71pmif.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "checkout",
            "endpoint": "https://pwfgi4sosc.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "verify",
            "endpoint": "https://421rnfw1ce.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "pixabay",
            "endpoint": "https://gnunxijd6j.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "craftcards-20200411092328-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3jhii97fsdme6.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:d1dec0f4-346f-41f1-b68c-ba06245d5ede",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_b9R1CItMv",
    "aws_user_pools_web_client_id": "31701ctk9vkpqj2jv28i7ln8uc",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "craft-cards-files162452-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
