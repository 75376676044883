import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend";
import { event } from './gtag';

import en from '../lang/en.json'
import es from '../lang/es.json'
import ar from '../lang/ar.json'
import fr from '../lang/fr.json'
import it from '../lang/it.json'
import pl from '../lang/pl.json'
import sr from '../lang/sr.json'
import ru from '../lang/ru.json'
import ro from '../lang/ro.json'
import tr from '../lang/tr.json'
import zh from '../lang/zh.json'

export default i18n
  .use(XHR)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
        order: ['querystring', 'navigator'],
    },
    resources: {
      ar: {
        common: ar
      },
      en: {
        common: en
      },
      es: {
        common: es
      },
      fr: {
        common: fr
      },
      it: {
        common: it
      },     
      pl: {
        common: pl
      },        
      ro: {
        common: ro
      },
      ru: {
        common: ru
      },
      sr: {
        common: sr
      },
      tr: {
        common: tr
      },
      zh: {
        common: zh
      }      
    },
    fallbackLng: "en",
    ns: ['common'],
    defaultNS: 'common',
    supportedLngs: ['ar', 'en', 'es', 'fr', 'it', 'pl', 'ro', 'ru', 'sr', 'tr', 'zh'],
    interpolation: {
      escapeValue: false
    }
  });

i18n.changeLanguage();

event({
  'action': 'detect',
  'category': 'language',
  'label': i18n.language
}); 