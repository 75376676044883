export const GA_TRACKING_ID = 'UA-143940930-1'

window.dataLayer = window.dataLayer || [];

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
  if(window.gtag){
    window.gtag('config', GA_TRACKING_ID, {
      page_location: url
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if(window.gtag){
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    })
  }
}