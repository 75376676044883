import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

function Prompt({display}) {
  const { t } = useTranslation();
  const [show, setShow] = useState('');

  useEffect(() => {
    setTimeout(()=>{
      setShow('show')
    },500)
  },[]);

  return (
    <div style={{display:display ? "block": "none"}} className={`prompt ${show}`} onClick={()=>{ setShow("none"); }}>
      {t("prompt.iosPart1", "Install this Application on your iphone: tap")} <img src="/home.png" height="144px" width="144px" alt="iOS home button" /> {t("prompt.iosPart2", "and then add to homescreen.")}
    </div>
  );
}

export default Prompt;
