import React, { useState, lazy, Suspense } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import HomeIcon from '@material-ui/icons/Home';
import PolicyIcon from '@material-ui/icons/Policy';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import StoreIcon from '@material-ui/icons/Store';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useIOSPrompt from '../hooks/useIOSPrompt';
import Prompt from './Prompt';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import '../../scss/app.scss';
import 'babel-polyfill';
import 'url-search-params-polyfill';
import { useCurrentAuthenticatedUser, logout } from '../user';
import '../i18n';
import { useTranslation } from "react-i18next";

// Content
const About = lazy(() => import('./About'));
const Cards = lazy(() => import('./Cards'));
const Creator = lazy(() => import('./Creator'));
const Home = lazy(() => import('./Home'));
const Confirm = lazy(() => import('./Confirm'));
const Forgot = lazy(() => import('./Forgot'));
const AWSLogin = lazy(() => import('./AWSLogin'));
const Store = lazy(() => import('./Store'));
const Profile = lazy(() => import('./Profile'));
const PurchaseSuccess = lazy(() => import('./PurchaseSuccess'));
const PurchaseCancel = lazy(() => import('./PurchaseCancel'));

const renderLoader = () => <p><img style={{margin: "20%  auto 0 auto", display: "block"}} alt="letter opening animation" height="256px" width="256px" src="/256x256.png"/></p>;

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '95%'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: '#fafafa'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: '100%'
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'middle',
    padding: '1.1em'
  },
  appBarLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'middle'
  },
  title: {
    flexGrow: 1
  }
}));

function ResponsiveDrawer(props) {
  const { t } = useTranslation();
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const showPrompt = useIOSPrompt();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [user, setUser] = useCurrentAuthenticatedUser();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
    setUser(undefined);
    window.location.href = '/';
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <div className={classes.logo}>
          <img src="/64x64.png" alt="Craft Cards logo" style={{height:"30px", width:"30px", marginRight: "20px"}} /> 
          <Typography variant="h6" noWrap>{t("app.name", "Craft Cards")}</Typography>
        </div>
      </div>
      <Divider />
      <List>
        {[{text:t('menu.home', 'Home'), icon:<HomeIcon />, href:"/"}, 
          {text:t('menu.create', 'Create A Card'), icon:<AddIcon />, href:"/create"},   
          {text:t('menu.give', 'Cards Crafted'), icon:<DraftsIcon />, href:"/give"},  
          {text:t('menu.receive', 'Cards Received'), icon:<InboxIcon />, href:"/receive"}, 
          {text:t('menu.store', 'Store'), icon:<StoreIcon />, href:"/store"}, 
          {text:t('menu.about', 'About'), icon:<PolicyIcon />, href:"/about"}
        ].map((item, index) => (
          <ListItem button key={item.text} component={Link} to={item.href}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Suspense fallback={renderLoader()}>
      <ThemeProvider theme={theme}>
        <Router history={props.history}>
          <div className={classes.root}>
            <CssBaseline />
            
            
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    style={{color:"rgba(0, 0, 0, 0.87)"}}
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >  
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}></Typography>
                  <div>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color={ user ? "secondary" : "default"}
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      { user && <MenuItem onClick={handleClose} component={Link} to="/profile">{t("menu.profile", "Profile")}</MenuItem> }
                      { user && <MenuItem onClick={handleClose} component={Link} to="/login">{t("menu.logout", "Logout")}</MenuItem> }
                      { !user && <MenuItem onClick={handleClose} component={Link} to="/login">{t("menu.login", "Login")}</MenuItem> }
                    </Menu>
                  </div>
                </Toolbar>
              </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  onClick={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
                <Route path="/about" component={About} />
                <Route path="/give">
                <Cards cardKey="created" />
                </Route>
                <Route path="/receive">
                  <Cards cardKey="received" />
                </Route>
                <Route path="/create/:id"><Creator user={user} /></Route>
                <Route path="/create"><Creator user={user} /></Route>
                <Route path="/edit"><Creator user={user} /></Route>
                <Route path="/login"><AWSLogin user={user} setUser={setUser} /></Route>
                <Route path="/confirm" component={Confirm} />
                <Route path="/forgot" component={Forgot} />
                <Route path="/store"><Store user={user} /></Route>
                <Route path="/profile"><Profile user={user} /></Route>
                <Route path="/purchase/success/:id"><PurchaseSuccess user={user} /></Route>
                <Route path="/purchase/cancel/:id"><PurchaseCancel user={user} /></Route>
                <Route path="/" component={Home} />
              </Switch>
            </main>
          </div>
        </Router>
        <Prompt display={showPrompt} />
      </ThemeProvider>
    </Suspense>
  );
}

export default ResponsiveDrawer;
